import React from "react"
import { Button, Typography } from "@mui/material"

import { navigate } from "gatsby"
import { toast } from "react-hot-toast"
import useSearchParam from "react-use/lib/useSearchParam"
import failedPaymentSVG from "../../images/failed-payment.svg"

export const FailedPaymentPage = () => {
  const name = useSearchParam("name")
  const plan = useSearchParam("plan")

  const onTryAgainClick = () => {
    if (plan) {
      navigate("/purchase/" + plan)
    } else {
      toast.error(
        "There was an error while loading this page. Please contact support"
      )
    }
  }

  return (
    <div style={{
        margin: "auto !important",
        display: "flex !important",
        flexWrap: "wrap",
        justifyContent: "center !important",
        alignContent: "center !important",
        flexDirection: "column",
        alignItems: "center !important",
        gap: "8px !important",
        textAlign: "center",
      }}
    >
      <img src={failedPaymentSVG} />
      <Typography variant="h2">Something went wrong</Typography>

      <Typography variant="body1">
        {name ? `${name}, l` : "L"}ooks like your payment didn't go through! Please
        try again or use a new method, and contact your bank if the problem occurs
        again.
      </Typography>

      <Button onClick={onTryAgainClick}>Try again</Button>
    </div>
  )
}
