import { Box } from '@mui/material'
import HeaderV2 from '@talentinc/gatsby-theme-ecom/components/HeaderV2/HeaderV2'
import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'
import { FailedPaymentPage } from '@talentinc/gatsby-theme-ecom/components/NewCheckout/FailedPaymentPage'
import { useCheckoutStyles } from '@talentinc/gatsby-theme-ecom/components/NewCheckout/styles'
import { PageDataContextType } from '@talentinc/gatsby-theme-ecom/types/page'
import { graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

type Props = {
  data: PageDataContextType
}

export default function Index(props: Props) {
  const { classes } = useCheckoutStyles()

  return (
    <Layout pageData={props.data}>
      <Helmet>
        <title>Failed Payment</title>
      </Helmet>

      <Box className={classes.mainContainer}>
        <Box className={classes.pageContainer}>
          <HeaderV2 noNavigation position="relative" />
          <FailedPaymentPage />
        </Box>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query Purchase {
    brand: contentfulBrand(name: { eq: "TopCV" }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(brand: { elemMatch: { name: { eq: "TopCV" } } }) {
      ...HeaderV2
    }
    footer: contentfulFooter(brand: { name: { eq: "TopCV" } }) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
